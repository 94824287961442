import React from 'react';
import { cn } from '@/utils/style';
import XSSVG from 'public/assets/support-chat/x.svg';
import ChatSVG from 'public/assets/support-chat/chat.svg';

const LeftIcon = ({ isOpen }: { isOpen?: boolean }) => {
  return (
    <span className="relative flex h-full w-full items-center justify-center">
      <span
        className={cn([
          'absolute inset-0 flex items-center justify-center transition-opacity duration-300',
          isOpen ? 'opacity-0' : 'opacity-100',
        ])}
      >
        <ChatSVG />
      </span>
      <span
        className={cn([
          'absolute inset-0 flex items-center justify-center transition-opacity duration-300',
          isOpen ? 'opacity-100' : 'opacity-0',
        ])}
      >
        <XSSVG />
      </span>
    </span>
  );
};

export default LeftIcon;
