'use client';

import { I18nextProvider } from 'react-i18next';
import { createInstance } from 'i18next';
import React from 'react';
import { initTranslations } from '../i18n';

const LanguageProvider: React.FC<{
  lang: string;
  resources?: any;
  children: React.ReactNode;
}> = ({ lang, resources, children }) => {
  const i18n = createInstance();

  initTranslations(lang, undefined, i18n, resources);
  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
};

export default LanguageProvider;
