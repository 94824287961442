import React from 'react';

export enum SearchType {
  GAMES,
  PROVIDERS,
}

export type SearchTypeItem = {
  id: string;
  component: string | React.ReactNode;
};
