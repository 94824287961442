'use client';

function isPWA() {
  if (typeof window === 'undefined') {
    // If we're not in a browser environment, return false
    return false;
  }

  return (
    //@ts-ignore
    window.navigator.standalone == true || // iOS PWA Standalone
    document.referrer.includes('android-app://') || // Android Trusted Web App
    ['fullscreen', 'standalone', 'minimal-ui'].some(
      (displayMode) =>
        window.matchMedia('(display-mode: ' + displayMode + ')').matches,
    )
  ); // Chrome PWA (supporting fullscreen, standalone, minimal-ui)
}

export const initOneSignalClient = () => {
  if (typeof window === 'undefined') return;
  //@ts-ignore
  window.OneSignalDeferred = window.OneSignalDeferred || [];
  //@ts-ignore
  window.OneSignalDeferred.push(async function (OneSignal) {
    await OneSignal.init({
      appId: process.env.NEXT_PUBLIC_ONESIGNAL as string,
      serviceWorkerPath: '/OneSignalSDKWorker.js',
      notifyButton: {
        enable: true,
      },
    });
    if (isPWA()) {
      OneSignal?.User.addTag('pwa', 'true');
    }
  });
};
