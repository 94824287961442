'use client';
import React from 'react';
import { Modal } from '@purposeinplay/core-v2';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { setIsSupportOpen } from '@/app/reducers/bootstrap';
import useMediaQuery, {
  LG_BREAKPOINT,
  MD_BREAKPOINT,
} from '@/hooks/use-media-query';
import useClickOutsideContainers from '../hooks/use-click-outside-containers';
import SupportContent from './support-content';

const SupportContainer = () => {
  const { matchesQuery: isLG } = useMediaQuery(LG_BREAKPOINT);
  const { matchesQuery: isMD } = useMediaQuery(MD_BREAKPOINT);
  const isOpen = useAppSelector((state) => state.bootstrap.isSupportOpen);
  const dispatch = useAppDispatch();

  const setIsOpen = (param: boolean) => {
    dispatch(setIsSupportOpen(param));
  };

  // Custom hook to handle clicks outside button and support
  useClickOutsideContainers(
    'support-container',
    'floating-support-button',
    () => dispatch(setIsSupportOpen(false)),
    isOpen,
  );

  if (!isOpen) return null;

  const containerStyles =
    'fixed bottom-[5.5rem] right-5 z-[100] flex h-auto w-[352px] flex-col space-y-4 rounded-radius-minimal bg-bgr-modal p-4';

  const modalStylingOptions = {
    titleStyles: 'pointer-events-none opacity-0',
    wrapperStyles: isMD ? '' : 'mt-auto absolute bottom-0 right-0 left-0',
    closeBtnContainerStyles: 'top-2 md:top-[1rem]',
  };

  if (isLG) {
    return (
      <div id="support-container" className={containerStyles}>
        <SupportContent setIsOpen={setIsOpen} />
      </div>
    );
  }

  return (
    <Modal
      title="Placeholder"
      modalStylingOptions={modalStylingOptions}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <SupportContent setIsOpen={setIsOpen} />
    </Modal>
  );
};

export default SupportContainer;
