/**
 * Function used to extract strapi content by locale
 * @param locale
 * @param content
 * @returns content
 */

import { CONTENT_ENDPOINT } from './content-api';
import { PagesDocument } from '@/generated';
import {
  APP_DEFAULT_TITLE,
  APP_DESCRIPTION,
  APP_TITLE_TEMPLATE,
  IS_PRODUCTION,
  METADATA_OBJECT,
} from './constants';
import { capitalizedWord } from './helpers';
import config from '@/config';

export const extractTranslations = (locale: string, content: any) => {
  if (!locale || locale === 'en') return content;
  if (content?.localizations?.data) {
    const foundContent = content?.localizations?.data?.find(
      (item: any) => item?.attributes?.locale === locale,
    );

    return foundContent?.attributes ?? content;
  }

  return content;
};

/**
 * Function used to generate the SEO content for current page path
 * @param lang
 * @param path
 * @param options
 * @returns METADATA_OBJECT
 */

export const generateSEOContent = async (
  lang: string,
  path: string,
  options?: any,
) => {
  const pageNumber = options?.options?.page;

  const data = await fetch(CONTENT_ENDPOINT, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },

    body: JSON.stringify({
      query: PagesDocument,
      variables: {
        path,
      },
    }),
    next: { revalidate: false },
  })
    .then((res) => res.json())
    .then((res) => res?.data);

  const currentPage = data?.pages && data?.pages?.data[0]?.attributes;
  const pageContent = extractTranslations(lang, currentPage);
  const noReferer = pageContent?.meta?.noReferer;
  const noIndex = pageContent?.meta?.noIndex;

  let canonicalURL;

  if (pageContent?.meta?.canonicalURL) {
    canonicalURL = pageContent?.meta?.canonicalURL;
  }

  const baseUrl = config.SITE_URL;
  let basePath;

  if (options?.options?.isForCategory) {
    basePath = '/categories';
  } else if (options?.options?.isForCollection) {
    basePath = '/collections';
  } else {
    basePath = '';
  }

  canonicalURL = `${baseUrl}${basePath}${path}${pageNumber ? `?page=${pageNumber}` : ''}`;

  const prevPageUrl =
    pageNumber && pageNumber > 1
      ? `${baseUrl}${basePath}${path}?page=${Number(pageNumber) - 1}`
      : `${baseUrl}${basePath}${path}`;

  const nextPageUrl = `${baseUrl}${basePath}${path}?page=${Number(pageNumber) + 1}`;

  const collectionId = capitalizedWord(path.replace('/', ''))
    .replace('-', ' ')
    .replace('games', '');

  const getTitle = () => {
    if (pageContent?.SEO.title) {
      return options?.page
        ? `Page ${options?.page} - ${pageContent?.SEO.title}`
        : pageContent?.SEO.title;
    }

    if (options?.isForCategory) {
      return options?.page
        ? `Page ${options?.page} - Crypto Casino Games | Play ${collectionId} games with BTC or crypto at Wild.io Casino`
        : `Crypto Casino Games | Play ${collectionId} games with BTC or crypto at Wild.io Casino`;
    }

    return APP_DEFAULT_TITLE;
  };

  const getDescription = () => {
    if (pageContent?.SEO.description) {
      return options?.page
        ? `Page ${options?.page} - ${pageContent?.SEO.description}`
        : pageContent?.SEO.description;
    }

    if (options?.isForCategory) {
      return options?.page
        ? `Page ${options?.page} - Play online casino Slots, Table Games, and ${collectionId} with BTC, ETH, BNB, and other crypto, at Wild.io Crypto Casino! Use deposit bonuses and free spins to win big!`
        : `Play online casino Slots, Table Games, and ${collectionId} with BTC, ETH, BNB, and other crypto, at Wild.io Crypto Casino! Use deposit bonuses and free spins to win big!`;
    }

    return APP_DESCRIPTION;
  };

  return {
    ...METADATA_OBJECT,
    title: {
      ...(METADATA_OBJECT.title as any),
      default: getTitle(),
    },
    referrer: noReferer ? 'no-referrer' : 'origin',
    robots: !IS_PRODUCTION
      ? 'noindex, nofollow'
      : noIndex
        ? 'noindex, nofollow'
        : 'index, follow',
    description: getDescription(),
    alternates: {
      canonical: canonicalURL,
    },
    openGraph: {
      ...METADATA_OBJECT['openGraph'],
      title: {
        default: getTitle(),
        template: APP_TITLE_TEMPLATE,
      },
      description: getDescription(),
    },
    icons: {
      other: [
        { rel: 'next', url: nextPageUrl },
        { rel: 'prev', url: prevPageUrl },
      ],
    },
    ...options,
  };
};
