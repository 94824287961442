import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FilteredGame } from '@/types/games_filters';
import { isBrowser } from 'mobile-device-detect';

interface WalletDynamicContent {
  isOpen: 'default' | 'details' | 'bonus' | 'bonus-details';
  data: any;
  bonusToPreview?: any;
}

interface BonusSectionDynamicContent {
  isOpen: 'default' | 'details';
  data: any;
}

interface BonusDetailsDynamicContent {
  data: any;
}

// Define a type for the slice state
interface Bootstrap {
  selectedAvatar: string | undefined | null;
  savedAvatar: string | undefined | null;
  gameViews: any[];
  isMinimized: boolean;
  viewFiat: boolean;
  isFun: boolean;
  isSidemenuOpen: boolean;
  isChatOpen: boolean;
  isSupportOpen: boolean;
  openTriggerModal:
    | 'updated_info'
    | 'updated_terms'
    | 'changePasswordModal'
    | 'depositModal'
    | 'userConfirmationModal'
    | 'fill-profile'
    | 'addToGroup'
    | 'profile_limits'
    | undefined;
  gameViewOpen: boolean;
  openFeed: boolean;
  hideZeroBalances: boolean;
  walletDynamicContent: WalletDynamicContent;
  bonusSelctionDynamicContent: BonusSectionDynamicContent;
  bonusDetailsDynamicContent: BonusDetailsDynamicContent;
  openGameOverlay?: boolean;
}

interface GameViewPayload extends FilteredGame {
  url: string;
  status?: string | undefined;
  isFun?: boolean;
}

// Define the initial state using that type
const initialState: Bootstrap = {
  selectedAvatar: null,
  savedAvatar: null,
  gameViews: [null, null, null, null],
  isMinimized: false,
  viewFiat: true,
  isFun: false,
  openFeed: false,
  isSidemenuOpen: isBrowser,
  isSupportOpen: false,
  isChatOpen: false,
  gameViewOpen: true,
  hideZeroBalances: false,
  walletDynamicContent: {
    isOpen: 'default',
    data: null,
    bonusToPreview: null,
  },
  bonusSelctionDynamicContent: {
    isOpen: 'default',
    data: null,
  },
  bonusDetailsDynamicContent: {
    data: null,
  },
  openTriggerModal: undefined,
  openGameOverlay: true,
};

export const bootstrapSlice = createSlice({
  name: 'bootstrap',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setViewFiat: (state, action: PayloadAction<boolean>) => {
      state.viewFiat = action.payload;
    },
    setOpenSidemenu: (state, action: PayloadAction<boolean>) => {
      state.isSidemenuOpen = action.payload;
    },
    setOpenChat: (state, action: PayloadAction<boolean>) => {
      state.isChatOpen = action.payload;
    },

    setIsSupportOpen: (state, action: PayloadAction<boolean>) => {
      state.isSupportOpen = action.payload;
    },

    setOpenTriggerModal: (
      state,
      action: PayloadAction<Bootstrap['openTriggerModal']>,
    ) => {
      state.openTriggerModal = action.payload;
    },
    setIsMinimized: (state, action: PayloadAction<boolean>) => {
      state.isMinimized = action.payload;
    },
    setIsFun: (state, action: PayloadAction<boolean>) => {
      state.isFun = action.payload;
    },
    setIsFunForGameView: (
      state,
      action: PayloadAction<{ index: number; isFun: boolean }>,
    ) => {
      const { index, isFun } = action.payload;
      if (
        index >= 0 &&
        index < state.gameViews.length &&
        state.gameViews[index]
      ) {
        state.gameViews[index].isFun = isFun;
      }
    },
    setHideZeroBalances: (state, action: PayloadAction<boolean>) => {
      state.hideZeroBalances = action.payload;
    },
    setSavedAvatar: (state, action: PayloadAction<string | null>) => {
      state.savedAvatar = action.payload;
    },
    setSelectedAvatar: (state, action: PayloadAction<string>) => {
      state.selectedAvatar = action.payload;
    },
    setGameView: (state, action: PayloadAction<GameViewPayload | null>) => {
      if (action.payload === null) {
        state.gameViews[0] = null;
      } else if (state.gameViews[0]) {
        state.gameViews[0] = { ...state.gameViews[0], ...action.payload };
      } else {
        state.gameViews[0] = action.payload;
      }
    },
    setGameViewOpen: (state, action: PayloadAction<boolean>) => {
      state.gameViewOpen = action.payload;
    },
    setOpenFeed: (state, action: PayloadAction<boolean>) => {
      state.openFeed = action.payload;
    },
    setWalletDynamicContent: (
      state,
      action: PayloadAction<WalletDynamicContent>,
    ) => {
      state.walletDynamicContent = action.payload;
    },
    setBonusSelectionDynamicContent: (
      state,
      action: PayloadAction<BonusSectionDynamicContent>,
    ) => {
      state.bonusSelctionDynamicContent = action.payload;
    },
    setBonusDetailsnDynamicContent: (
      state,
      action: PayloadAction<BonusDetailsDynamicContent>,
    ) => {
      state.bonusDetailsDynamicContent = action.payload;
    },
    setOpenGameOverlay: (state, action: PayloadAction<boolean>) => {
      state.openGameOverlay = action.payload;
    },
    setGameViewAt: (
      state,
      action: PayloadAction<{
        index: number;
        gameView: GameViewPayload | null;
      }>,
    ) => {
      const { index, gameView } = action.payload;
      if (index >= 0 && index < state.gameViews.length) {
        if (gameView === null) {
          state.gameViews[index] = null;
        } else if (state.gameViews[index]) {
          state.gameViews[index] = { ...state.gameViews[index], ...gameView };
        } else {
          state.gameViews[index] = gameView;
        }
      }
    },
  },
});

export const {
  setViewFiat,
  setOpenSidemenu,
  setIsSupportOpen,
  setIsMinimized,
  setIsFun,
  setIsFunForGameView,
  setHideZeroBalances,
  setSavedAvatar,
  setSelectedAvatar,
  setGameView,
  setGameViewAt,
  setGameViewOpen,
  setOpenFeed,
  setWalletDynamicContent,
  setOpenTriggerModal,
  setOpenGameOverlay,
  setBonusSelectionDynamicContent,
  setBonusDetailsnDynamicContent,
  setOpenChat,
} = bootstrapSlice.actions;

export default bootstrapSlice.reducer;
