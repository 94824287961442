'use client';
import { useTranslation } from '@/app/i18n/client';
import { GeneralContentType } from '@/types/json-content/general';
import { Button, Text } from '@purposeinplay/core-v2';
import React from 'react';
import ErrorSvg from 'public/assets/error-outline.svg';

const FallbackError = React.memo(({ ...props }: any) => {
  const { t } = useTranslation();
  const general = t('general') as GeneralContentType;

  return (
    <div
      {...props}
      className="flex flex-row items-center gap-2 rounded-lg bg-bgr-lighter p-6"
    >
      <ErrorSvg className="h-5 w-5" />
      <div>
        <Text>{general?.fallback_error?.title}</Text>
        <Text className="text-sm font-medium text-text-subdued">
          {general?.fallback_error?.description}
        </Text>
      </div>
    </div>
  );
});

FallbackError.displayName = 'FallbackError';

export default FallbackError;
