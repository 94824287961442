import i18next, {
  createInstance,
  Namespace,
  FlatNamespace,
  KeyPrefix,
  i18n,
  TFunction,
} from 'i18next';
import { FallbackNs, UseTranslationOptions } from 'react-i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next/initReactI18next';
import { getOptions } from './settings';
import { CONTENT_ENDPOINT } from '@/utils/content-api';
import { JsonContentDocument } from '@/generated';
import { extractTranslations } from '@/utils/content';
import { JsonContentType } from '@/types/json-content';

export const initTranslations = async (
  lng: string,
  ns?: string | string[],
  i18n?: any,
  resources?: any,
) => {
  // on server side we create a new instance for each render, because during compilation everything seems to be executed in parallel
  const i18nInstance = i18n || createInstance();
  await i18nInstance
    .use(initReactI18next)
    .use(
      resourcesToBackend(async (language: string, namespace: any) => {
        const data = await fetch(CONTENT_ENDPOINT, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },

          body: JSON.stringify({
            query: JsonContentDocument,
            variables: {
              locale: language,
            },
          }),
        })
          .then((res) => res.json())
          .then((res) => res?.data);

        const content = data?.content?.data?.attributes;
        const translations = extractTranslations(
          language,
          content,
        ) as JsonContentType;
        return translations;
      }),
    )
    .init({ ...getOptions(lng, ns), resources });
  return i18nInstance;
};

export async function useTranslation<
  Ns extends FlatNamespace,
  KPrefix extends KeyPrefix<FallbackNs<Ns>> = undefined,
>(lng: string, ns?: Ns, options: { keyPrefix?: KPrefix } = {}) {
  const i18nextInstance = await initTranslations(
    lng,
    Array.isArray(ns) ? (ns as string[]) : (ns as string),
  );
  return {
    t: i18nextInstance.getFixedT(lng, ns, options.keyPrefix),
    i18n: i18nextInstance,
  };
}

export async function getTranslations(
  lng: string,
  options?: UseTranslationOptions<any>,
): Promise<{
  i18n: i18n;
  t: <K extends keyof any>(key: K) => any;
}> {
  const i18nextInstance = await initTranslations(lng);

  const t = <K extends keyof any>(key: K) => {
    return i18nextInstance.getFixedT(lng, null, null)(key) as any;
  };

  return {
    i18n: i18nextInstance,
    t,
  };
}
