import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import client from '../utils/api';
import { useAccounts } from './accounts';
import { Currency } from '@/types/currency';
import { useMemo } from 'react';
import { EXCLUDED_CURRENCIES } from '@/utils/constants';

const useCurrencies = (
  options?: Omit<
    UseQueryOptions<any, any, any, any>,
    'queryKey' | 'queryFn' | 'initialData'
  > & {
    initialData?: () => undefined;
  },
): { data: Array<any>; isLoading: boolean } => {
  const { data = [], isLoading } = useQuery({
    queryKey: ['currencies'],
    queryFn: async () => await client('info/currencies'),
    retry: false,
    ...options,
  });

  const currencies = data?.filter(
    (curr: Currency) => !EXCLUDED_CURRENCIES.includes(curr.code),
  );

  return {
    data: currencies,
    isLoading,
  };
};

const useCurrency = () => {
  const { data: currencies } = useCurrencies();
  const { account = {} } = useAccounts();
  return currencies.find(
    (currencyItem: any) => currencyItem.code === account.currency,
  );
};

const useCurrencyObj = (currency: string) => {
  const { data: currencies } = useCurrencies();
  return currencies.find(
    (currencyItem: any) => currencyItem.code === currency?.toUpperCase(),
  ) as Currency;
};

export { useCurrencies, useCurrency, useCurrencyObj };
