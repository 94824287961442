'use client';
import { ThemeProvider } from 'next-themes';
import LiveChatLoaderProvider from '@/services/live-chat/live-chat-provider';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import config from '@/config';
import AuthProvider from './auth';
import { Provider as ReduxProvider } from 'react-redux';
import store from '../store/store';
import RealtimeProvider from './realtime';
import LanguageProvider from './language';
import { NotificationsWrapper } from './notifications';
import { Toaster } from '@purposeinplay/core-v2';
import { useEffect, useRef } from 'react';
import { initOneSignalClient } from '@/services/one-signal';
import ChatProvider from './chat';
import { ScreenLayoutProvider } from '@/modules/games/context/screen-layout-context';

type Props = {
  children: React.ReactNode;
  theme?: string;
  resources?: any;
  lang: string;
};

const Providers: React.FC<Props> = ({ lang, resources, children, theme }) => {
  const initialized = useRef(false);

  useEffect(() => {
    if (initialized.current) return;
    initOneSignalClient();
    initialized.current = true;
  }, []);

  return (
    <ThemeProvider
      attribute="data-theme"
      defaultTheme="dark"
      forcedTheme={theme}
    >
      <LanguageProvider lang={lang} resources={resources}>
        <GoogleReCaptchaProvider
          scriptProps={{
            defer: true,
            nonce: undefined,
          }}
          reCaptchaKey={config.RECAPTCHA_KEY as string}
        >
          <LiveChatLoaderProvider
            provider="intercom"
            providerKey="wnwkh2fl"
            beforeInit={() => {
              window.intercomSettings = {
                // @ts-ignore
                hide_default_launcher: true,
              };
            }}
          >
            <ReduxProvider store={store}>
              <AuthProvider>
                <RealtimeProvider>
                  <ChatProvider>
                    <NotificationsWrapper>
                      <ScreenLayoutProvider>
                        {children}
                        <Toaster className="fixed right-2 top-2 z-[9999]" />
                      </ScreenLayoutProvider>
                    </NotificationsWrapper>
                  </ChatProvider>
                </RealtimeProvider>
              </AuthProvider>
            </ReduxProvider>
          </LiveChatLoaderProvider>
        </GoogleReCaptchaProvider>
      </LanguageProvider>
    </ThemeProvider>
  );
};

export default Providers;
