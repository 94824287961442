'use client';

import React, { useMemo, useState } from 'react';
import * as Providers from './providers';
import { State, Provider } from './types';
import { LiveChatLoaderContext as LiveChatContext } from './context';

interface LiveChatLoaderProps {
  provider: Provider;
  children: React.ReactNode;
  idlePeriod?: number;
  providerKey: string;
  appID?: string;
  baseUrl?: string;
  beforeInit?: () => void;
  onReady?: () => void;
}

const LiveChatLoaderProvider = ({
  provider,
  children,
  idlePeriod = 5000,
  baseUrl,
  ...props
}: LiveChatLoaderProps): any | null => {
  const [state, setState] = useState<State>('initial');

  const chatProvider = Providers[provider];

  const value = useMemo(
    () => ({
      provider,
      idlePeriod,
      state,
      update: chatProvider?.update,
      boot: chatProvider?.boot,
      identify: chatProvider?.identify,
      shutdown: chatProvider?.shutdown,
      setState,
      baseUrl,
      ...props,
    }),
    [
      provider,
      idlePeriod,
      state,
      chatProvider?.update,
      chatProvider?.boot,
      chatProvider?.identify,
      chatProvider?.shutdown,
      baseUrl,
      props,
    ],
  );

  if (!chatProvider) {
    console.error(`Unknown provider given: ${provider}`);
    return null;
  }

  return (
    // @ts-ignore
    <LiveChatContext.Provider value={value}>
      <link
        href={baseUrl || chatProvider.domain}
        rel="preconnect"
        crossOrigin=""
      />
      {children}
    </LiveChatContext.Provider>
  );
};

export default LiveChatLoaderProvider;
