import { Group } from '@/types/groups';
import client from '@/utils/api';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';

const useGroups = (): UseQueryResult<Group[]> => {
  return useQuery({
    queryKey: ['groups'],
    queryFn: () => client('info/statuses'),
  });
};

const usePlayerGroups = (): UseQueryResult<Group[]> => {
  return useQuery({
    queryKey: ['playerGroups'],
    queryFn: () => client('player/status_groups'),
  });
};

const useAddPlayerToGroups = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (groups: string[]) => {
      try {
        return client(
          'player/groups',
          {
            groups: {
              add: groups,
            },
          },
          'POST',
        );
      } catch (e) {
        throw e;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['currentUser'] });
    },
  });
};

const useRemovePlayerFromGroups = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (groups: string[]) => {
      try {
        return client(
          'player/groups',
          {
            groups: {
              remove: groups,
            },
          },
          'POST',
        );
      } catch (e) {
        throw e;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['currentUser'] });
    },
  });
};

export {
  useGroups,
  usePlayerGroups,
  useAddPlayerToGroups,
  useRemovePlayerFromGroups,
};
