'use client';

import { IS_DEV } from '@/utils/constants';
import { datadogRum } from '@datadog/browser-rum';
import { useEffect } from 'react';

const initDataDog = () => {
  if (!IS_DEV && !datadogRum.getInitConfiguration()) {
    datadogRum.init({
      applicationId: '84aba7b2-4b7a-4c87-9f2c-f6698afc95f6',
      clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT as string,
      site: 'datadoghq.eu',
      service: 'wild-frontend',
      env: process.env.NODE_ENV,
      sessionSampleRate: 10,
      sessionReplaySampleRate: 10,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingUrls: [
        { match: 'https://wild.io/api/', propagatorTypes: ['tracecontext'] },
      ],
    });
  }
};

export default function Datadog() {
  useEffect(() => {
    initDataDog();
  }, []);
  return null;
}
