import { useEffect } from 'react';

const useClickOutsideContainers = (
  elementId: string,
  buttonId: string,
  callback: () => void,
  isActive: boolean,
) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      const button = document.getElementById(buttonId);
      const element = document.getElementById(elementId);

      if (
        isActive &&
        target !== button &&
        !button?.contains(target) &&
        element &&
        !element.contains(target)
      ) {
        callback();
      }
    };

    if (isActive) {
      document.addEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [elementId, buttonId, callback, isActive]);
};

export default useClickOutsideContainers;
