'use client';
import { IconButton } from '@purposeinplay/core-v2';
import React from 'react';
import LeftIcon from './components/left-icon';
import { useAppSelector } from '@/hooks/redux';
import useIsOnGamePage from '@/hooks/use-is-on-game-page';
import { useSupportChatToggle } from '@/hooks/use-support-chat-toggle';

const FloatingSupport = () => {
  const isOpen = useAppSelector((state) => state.bootstrap.isSupportOpen);
  const isOnGamePage = useIsOnGamePage();

  const { handleClick } = useSupportChatToggle();

  if (isOnGamePage) return null;

  return (
    <div
      id="floating-support-button"
      className="fixed bottom-5 right-5 z-[100] hidden  items-center justify-center  lg:block"
    >
      <IconButton
        className={'!h-14 !w-14 !rounded-full !p-0'}
        iconStyles="flex items-center justify-center !w-full !h-full"
        onClick={handleClick}
        iconOnly
        iconLeft={<LeftIcon isOpen={isOpen} />}
      />
    </div>
  );
};

export default FloatingSupport;
