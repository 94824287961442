import { IconButton, Text } from '@purposeinplay/core-v2';
import React from 'react';
import ChatSVG from 'public/assets/support-chat/chat-white.svg';
import { useTranslation } from '@/app/i18n/client';
import { HomepageContentType } from '@/types/json-content/homepage';
import { useChat } from '@/services/live-chat/use-chat';

const LiveChatButton = ({
  setIsOpen,
}: {
  setIsOpen?: (param: any) => void;
}) => {
  const [state, loadChat] = useChat({ loadWhenIdle: false });

  const { t } = useTranslation();
  const content = t('homepage') as HomepageContentType;
  const floatingSupportContent = content?.floating_support;

  const handleClick = () => {
    loadChat({ open: true });
    setIsOpen && setIsOpen(false);
  };

  return (
    <IconButton
      onClick={handleClick}
      iconLeft={<ChatSVG />}
      color="tertiary"
      className="w-full [&>span:first-of-type]:translate-y-0.5 [&>span>svg]:fill-current [&>span]:-mx-1"
    >
      <Text size="sm" as="span" className="font-semibold leading-6">
        {floatingSupportContent?.buttons?.intercom}
      </Text>
    </IconButton>
  );
};

export default LiveChatButton;
