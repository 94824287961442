'use client';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { setGameViewOpen } from '@/app/reducers/bootstrap';
import { cn } from '@/utils/style';
import { usePathname } from 'next/navigation';
import './styles/route-loader.css';

const DONE_DURATION = 500;

const RouteLoader = () => {
  const [loading, setLoading] = useState(false);
  const pathname = usePathname();
  const dispatch = useAppDispatch();

  const isMinimized = useAppSelector((state) => state.bootstrap.isMinimized);

  useEffect(() => {
    setLoading(true);
    const handleRouteChange = () => {
      !isMinimized && dispatch(setGameViewOpen(true));
      const timeoutId = setTimeout(() => {
        setLoading(false);
      }, DONE_DURATION);

      return () => clearTimeout(timeoutId);
    };

    handleRouteChange();
  }, [pathname, dispatch]);

  return (
    <div
      className={cn([
        'loader fixed left-0 right-full top-0 z-[999] h-[2px] bg-[#1ab852] opacity-100',
        loading === null ? '' : loading ? 'loading' : 'done',
      ])}
    ></div>
  );
};

export default RouteLoader;
