import(/* webpackMode: "eager" */ "/build/image-loader.js");
;
import(/* webpackMode: "eager", webpackExports: ["HydrationBoundary"] */ "/build/node_modules/@tanstack/react-query/build/modern/HydrationBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["IsRestoringProvider","useIsRestoring"] */ "/build/node_modules/@tanstack/react-query/build/modern/isRestoring.js");
;
import(/* webpackMode: "eager" */ "/build/node_modules/@tanstack/react-query/build/modern/QueryClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["QueryErrorResetBoundary","useQueryErrorResetBoundary"] */ "/build/node_modules/@tanstack/react-query/build/modern/QueryErrorResetBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["useInfiniteQuery"] */ "/build/node_modules/@tanstack/react-query/build/modern/useInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsFetching"] */ "/build/node_modules/@tanstack/react-query/build/modern/useIsFetching.js");
;
import(/* webpackMode: "eager", webpackExports: ["useMutation"] */ "/build/node_modules/@tanstack/react-query/build/modern/useMutation.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsMutating","useMutationState"] */ "/build/node_modules/@tanstack/react-query/build/modern/useMutationState.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQueries"] */ "/build/node_modules/@tanstack/react-query/build/modern/useQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQuery"] */ "/build/node_modules/@tanstack/react-query/build/modern/useQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseInfiniteQuery"] */ "/build/node_modules/@tanstack/react-query/build/modern/useSuspenseInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQueries"] */ "/build/node_modules/@tanstack/react-query/build/modern/useSuspenseQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQuery"] */ "/build/node_modules/@tanstack/react-query/build/modern/useSuspenseQuery.js");
;
import(/* webpackMode: "eager" */ "/build/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/build/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/build/node_modules/next/font/local/target.css?{\"path\":\"src/app/[lang]/layout.tsx\",\"import\":\"\",\"arguments\":[{\"variable\":\"--my-proxima\",\"display\":\"swap\",\"adjustFontFallback\":false,\"src\":[{\"path\":\"../fonts/proxima-nova/proximanova-extrabld.woff2\",\"weight\":\"700\",\"style\":\"normal\"},{\"path\":\"../fonts/proxima-nova/proximanova-bold.woff2\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"../fonts/proxima-nova/proximanova-semibold.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../fonts/proxima-nova/proximanova-light.woff2\",\"weight\":\"300\",\"style\":\"normal\"},{\"path\":\"../fonts/proxima-nova/proximanova-regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"}]}],\"variableName\":\"proximaFont\"}");
;
import(/* webpackMode: "eager", webpackExports: ["useTranslation"] */ "/build/src/app/i18n/client.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/build/src/app/providers/datadog.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/build/src/app/providers/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ReactQuery"] */ "/build/src/app/providers/react-query.tsx");
;
import(/* webpackMode: "eager" */ "/build/src/app/styles/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/build/src/components/FallbackError/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/build/src/components/RouteLoader/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/build/src/components/SupportChat/components/support-container.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/build/src/components/SupportChat/floating-support.tsx");
