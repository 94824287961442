import React, { useCallback, useMemo } from 'react';
import { useCurrencies } from './currencies';
import { formatMoney, DEFAULT_SUBUNITS_TO_UNIT } from '@purposeinplay/utils';

type SubunitsToUnit = Record<string, number>;
type FiatCurrencyMap = Record<string, string>;
interface FormatMoneyOptions {
  subunitsToUnit?: SubunitsToUnit;
  fiatCurrencyMap?: FiatCurrencyMap;
  locale?: string;
  style?: 'currency' | 'decimal';
  format?: string | false;
  currencyDisplay?: 'symbol' | 'code' | 'none';
  [key: string]: unknown; // Allow additional Intl.NumberFormat options
}

export const useCurrencyFormatter = () => {
  const { data: currencies = [], isLoading } = useCurrencies();
  const updatedSubunitsToUnit = React.useMemo(() => {
    if (isLoading || !currencies.length) {
      return {};
    }

    return currencies.reduce(
      (acc, currency) => ({
        ...acc,
        [currency.code]: currency.subunits_to_unit || 0,
      }),
      {},
    );
  }, [currencies, isLoading]);

  const units = useMemo(() => {
    if (updatedSubunitsToUnit) {
      return updatedSubunitsToUnit;
    }
    return undefined;
  }, [updatedSubunitsToUnit]);

  const formatCurrency = useCallback(
    (amount_cents: number, currency: string, options?: FormatMoneyOptions) => {
      if (!currency) {
        return formatMoney(0, 'BTC', {
          locale: 'en-US',
          subunitsToUnit: {
            ...DEFAULT_SUBUNITS_TO_UNIT,
            ...units,
            ...options?.subunitsToUnit,
          },
          style: 'decimal',
          ...options,
        });
      }
      if (!amount_cents) {
        return formatMoney(0, currency, {
          locale: 'en-US',
          subunitsToUnit: {
            ...DEFAULT_SUBUNITS_TO_UNIT,
            ...units,
            ...options?.subunitsToUnit,
          },
          style: 'decimal',
          ...options,
        });
      }
      return formatMoney(Number(amount_cents), currency, {
        locale: 'en-US',
        subunitsToUnit: {
          ...DEFAULT_SUBUNITS_TO_UNIT,
          ...units,
          ...options?.subunitsToUnit,
        },
        style: 'decimal',
        ...options,
      });
    },
    [units],
  );

  return { formatCurrency, units };
};
