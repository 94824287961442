import { useMemo } from 'react';
import { Currency } from '@/types/currency';
import { useCryptoRates } from './others';
import { useCurrencyFormatter } from './use-currency-formatter-wrapper';

const useCentsFromFiat = (currency: string | undefined, fiatAmount: number) => {
  const { units } = useCurrencyFormatter();

  if (!currency || !fiatAmount || fiatAmount === 0) return 0;

  return Math.round(fiatAmount * units[currency]);
};

const useCentsFromCrypto = (
  currency: string | undefined,
  cryptoAmount: number,
) => {
  const { formatCurrency, units } = useCurrencyFormatter();

  const centsBalance = useMemo(() => {
    if (!currency || !cryptoAmount || cryptoAmount === 0) return;
    return cryptoAmount * units[currency];
  }, [currency, cryptoAmount, units]);

  const formattedBalance = formatCurrency(
    centsBalance || 0,
    currency as string,
    {
      style: 'decimal',
    },
  );

  if (centsBalance) {
    return { centsBalance, formattedBalance };
  }

  return { centsBalance: 0, formattedBalance: '0.00' };
};

const useFiatFromCrypto = (currency: string | undefined, cryptoAmount: any) => {
  // Ensure cryptoAmount is a number and remove any commas
  const cleanedCryptoAmount = useMemo(() => {
    if (typeof cryptoAmount === 'string') {
      return parseFloat(cryptoAmount.replace(/,/g, '')); // Remove commas
    }
    return cryptoAmount;
  }, [cryptoAmount]);

  const { centsBalance } = useCentsFromCrypto(currency, cleanedCryptoAmount);

  const fiatBalance = useFiatFromCents(currency, centsBalance);

  if (fiatBalance) {
    return fiatBalance;
  }

  return '$0.00';
};

const useCryptoFromFiat = (
  currency: string,
  fiatAmount: any,
  precision?: number,
) => {
  const { data: cryptoListings, isLoading }: any = useCryptoRates();

  const foundCrypto = useMemo(
    () => cryptoListings?.find((crypto: any) => crypto?.symbol === currency),
    [currency, cryptoListings],
  );

  const cryptoBalance = useMemo(() => {
    const price = foundCrypto?.quote.USD.price;
    return fiatAmount / price;
  }, [foundCrypto, fiatAmount]);
  if (isLoading) {
    return '$0.00';
  }

  if (cryptoBalance) {
    return `${parseFloat(cryptoBalance.toString()).toFixed(precision || 4)}`;
  }

  return '$0.00';
};

const useFiatFromCents = (currency: string | undefined, cents: any) => {
  const { data: cryptoListings, isLoading }: any = useCryptoRates();
  const foundCrypto = useMemo(
    () => cryptoListings?.find((crypto: any) => crypto.symbol === currency),
    [currency, cryptoListings],
  );
  const { units } = useCurrencyFormatter();

  const fiatBalance = useMemo(() => {
    if (!currency || !cents || cents === 0) return;
    const price = foundCrypto?.quote.USD.price;
    const amount = cents / units[currency];
    return price * amount;
  }, [currency, cents, foundCrypto?.quote.USD.price, units]);

  if (isLoading || !currency || !cents) {
    return '$0.00';
  }
  if (fiatBalance) {
    const formattedFiatBalance = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(parseFloat(fiatBalance.toString()));
    const isNegative = parseFloat(fiatBalance.toString()) < 0;

    return isNegative
      ? `-$${formattedFiatBalance?.split('$')[1]}`
      : formattedFiatBalance;
  }

  return '$0.00';
};

export default useFiatFromCents;

const useCryptoPrice = (crypto: string) => {
  const { data: cryptoListings, isLoading }: any = useCryptoRates();
  const foundCrypto = useMemo(
    () =>
      cryptoListings?.find(
        (cryptoListing: any) => cryptoListing.symbol === crypto,
      ),
    [crypto, cryptoListings],
  );

  if (foundCrypto) {
    return {
      data: `$${parseFloat(
        //@ts-ignore
        foundCrypto?.quote.USD.price,
      ).toFixed(2)}`,
      isLoading,
    };
  }

  return {
    data: '$0.00',
    isLoading,
  };
};

export {
  useCryptoRates,
  useCryptoFromFiat,
  useFiatFromCrypto,
  useFiatFromCents,
  useCentsFromFiat,
  useCentsFromCrypto,
  useCryptoPrice,
};
