import { useTranslation } from '@/app/i18n/client';
import { HomepageContentType } from '@/types/json-content/homepage';
import { DEFAULT_IMAGE_LOADER } from '@/utils/constants';
import { Badge, IconButton, Text } from '@purposeinplay/core-v2';
import Image from 'next/image';
import TgSVG from 'public/assets/support-chat/tg-white.svg';
import React from 'react';

const TelegramCard = ({ setIsOpen }: { setIsOpen?: (param: any) => void }) => {
  const { t } = useTranslation();
  const content = t('homepage') as HomepageContentType;
  const floatingSupportContent = content?.floating_support;

  const handleTgClick = () => {
    window.open(
      'https://t.me/WildSupport_Bot',
      '_blank',
      'noopener,noreferrer',
    );
    setIsOpen && setIsOpen(false);
  };

  return (
    <div className="relative flex flex-col overflow-hidden rounded-md">
      <div
        style={{
          background: 'linear-gradient(278deg, #26A2DE 11.92%, #118ECB 77.77%)',
        }}
        className="pointer-events-none absolute inset-0"
      ></div>
      <div className="relative z-10 p-4">
        <div className="mb-2 flex justify-between">
          <Text className="max-w-40 font-semibold !text-white">
            {floatingSupportContent?.title}
          </Text>
          <div className="flex items-start justify-center">
            <Badge
              className="inline-flex bg-yellow-500"
              size="md"
              radius="rounded"
            >
              {floatingSupportContent?.badge_text}
            </Badge>
          </div>
        </div>
        <Text size="sm" className="mb-4 leading-5 !text-white">
          {floatingSupportContent?.description}
        </Text>
        <IconButton
          onClick={handleTgClick}
          iconLeft={<TgSVG />}
          color="tertiary"
          className="w-full !text-white [&>span]:-mx-1"
        >
          {floatingSupportContent?.buttons?.telegram}
        </IconButton>
      </div>
    </div>
  );
};

export default TelegramCard;
