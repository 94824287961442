import React from 'react';
import TelegramCard from './telegram-card';
import DashedOr from '@/components/DashedOr';
import LiveChatButton from './live-chat-button';

interface SupportContentProps {
  setIsOpen: (param: boolean) => void;
}

const SupportContent: React.FC<SupportContentProps> = ({ setIsOpen }) => {
  return (
    <>
      <TelegramCard setIsOpen={setIsOpen} />
      <DashedOr
        stylingOptions={{
          text: 'text-text-subdued text-xs',
        }}
      />
      <LiveChatButton setIsOpen={setIsOpen} />
    </>
  );
};

export default SupportContent;
