import { useMemo } from 'react';
import { useAccounts } from './accounts';
import { Account } from '@/types/accounts';
import { getIconByCurrency } from '@/utils/helpers';
import { EXCLUDED_CURRENCIES } from '@/utils/constants';

const useWalletSelector = (
  showAll = false,
  filteredCurrencies = [] as string[],
) => {
  const {
    accounts,
    isLoading: accountsLoading,
    account,
  } = useAccounts(showAll);
  const filteredAccounts = useMemo(() => {
    const excludedCurrencies = new Set(EXCLUDED_CURRENCIES);

    return (
      accounts
        ?.filter((acc: Account) => !excludedCurrencies.has(acc.currency))
        .filter(
          (acc: Account) =>
            !filteredCurrencies.length ||
            filteredCurrencies.includes(acc.currency),
        )
        .map((acc: Account) => {
          const currency = acc.currency;
          const balance = acc?.amount_cents;
          const withdrawableBalance = acc?.available_to_cashout_cents;
          const icon = getIconByCurrency(currency.toLowerCase());

          return {
            key: `currency-${currency.toLowerCase()}`,
            value: `${currency} - ${balance}`,
            icon,
            withdrawableBalance,
            balance,
            ...acc,
          };
        }) || []
    );
  }, [accounts, filteredCurrencies]);
  const foundAccount = useMemo(() => {
    return filteredAccounts.find(
      (acc: Account) => acc.currency === account?.currency,
    );
  }, [filteredAccounts, account?.currency]);

  return {
    account: foundAccount,
    formattedAccounts: filteredAccounts,
    isLoading: accountsLoading,
  };
};

export default useWalletSelector;
