import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';
import client from '@/utils/api';
import { useAuth } from './auth';
import { useRouter } from 'next/navigation';
import { useToast } from '@purposeinplay/utils';
import { useTranslation } from '@/app/i18n/client';
import { useOtherToastMessages } from '@/hooks/use-other-toast-messages';
import { handleError } from '@/utils/helpers';

interface WithdrawPayload {
  address: string;
  amount: number;
  currency: string;
  payment_action: string;
  payment_method: string;
}

interface ConfirmCashoutPayload {
  payment_id: number;
  token: string;
}

const usePlayerPayments = (): UseQueryResult<any[]> => {
  const { user } = useAuth();
  return useQuery({
    queryKey: ['playerPayments'],
    queryFn: () => client('player/payments'),
    enabled: !user?.isLoading && user?.isAuthenticated,
    refetchOnMount: true,
    throwOnError: true,
  });
};

const useWithdraw = (onSuccess?: () => void): UseMutationResult => {
  const { t } = useTranslation();
  const toastMessages = useOtherToastMessages();
  const { toast } = useToast();
  return useMutation({
    mutationFn: async (formData: any) => {
      return await client('payment_processing', { ...formData }, 'POST');
    },
    retry: false,
    onSuccess: () => {
      toast({
        state: 'success',
        title: toastMessages?.withdraw_success,
      });
      if (onSuccess) onSuccess();
    },
    onError: (err: any) => {
      const errorFields = ['currency', 'amount', 'address'];
      const errorMessages = errorFields
        .map((field) => handleError(err.errors, field))
        .filter(Boolean);

      if (!errorMessages.length) {
        toast({
          state: 'error',
          title: toastMessages?.general_error,
        });
      }
    },
  });
};

const useConfirmCashout = (): UseMutationResult => {
  const { t } = useTranslation();
  const toastMessages = useOtherToastMessages();
  const { toast } = useToast();
  const router = useRouter();
  return useMutation({
    mutationFn: async (payload: any) => {
      try {
        return client(
          'payment_processing/confirm_cashout_address',
          { payment_id: payload.payment_id, cashout_token: payload.token },
          'POST',
        );
      } catch (e) {
        throw e;
      }
    },
    retry: false,
    onSuccess: (data) => {
      router.push('/');
      if (data?.errors) {
        const value = Object.keys(data?.errors)[0];
        const err = data?.errors[value]?.invalid;
        console.log(err);

        toast({
          state: 'error',
          title: err != '' ? err : toastMessages?.general_error,
        });
      } else {
        toast({
          state: 'success',
          title: toastMessages?.confirm_cashout,
        });
      }
    },
    onError: () => {
      toast({
        state: 'error',
        title: toastMessages?.general_error,
      });
    },
  });
};

const useCancelCashout = (): UseMutationResult => {
  const { t } = useTranslation();
  const toastMessages = useOtherToastMessages();
  const { toast } = useToast();
  return useMutation({
    mutationFn: async (id: any) => {
      try {
        return client(`/player/payments/${id}/recall`, 'POST');
      } catch (e) {
        throw e;
      }
    },
    retry: false,
    onSuccess: () => {
      toast({
        state: 'success',
        title: toastMessages?.canceled_cashout,
      });
    },
    onError: () => {
      toast({
        state: 'error',
        title: toastMessages?.general_error,
      });
    },
  });
};

export { usePlayerPayments, useWithdraw, useConfirmCashout, useCancelCashout };
