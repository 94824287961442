import { cn } from '@/utils/style';
import React from 'react';

const DashedOr = ({
  stylingOptions,
}: {
  stylingOptions?: {
    container?: string;
    lineContainer?: string;
    line?: string;
    text?: string;
  };
}) => {
  const { container, lineContainer, line, text } = stylingOptions || {};
  return (
    <div
      className={cn([
        'relative my-4 flex w-full items-center justify-center md:my-7',
        container,
      ])}
    >
      <div
        className={cn(['absolute inset-0 flex items-center', lineContainer])}
      >
        <div
          className={cn(['w-full border-t border-surface-default', line])}
        ></div>
      </div>
      <div className={cn(['relative bg-bgr-modal px-4', text])}>OR</div>
    </div>
  );
};

export default DashedOr;
