import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Collection, Game } from '@/types/games';
import { FilteredGame, GameSort } from '@/types/games_filters';
import { ProviderItemProps } from '@/modules/category-page/types';
import { FetchNextPageOptions } from '@tanstack/react-query';
import { SearchType } from '@/modules/search/types';

type SearchResult = {
  data: FilteredGame[];
  pagination: any;
};
// Define a type for the slice state
interface Search {
  results: {
    pages: SearchResult[] | [];
    pageParams: number[];
  };
  resultActions: {
    isLoading: boolean;
    isFetching: boolean;
    isFetchingPrevPage: boolean;
    isFetchingNextPage: boolean;
    fetchNextPage: boolean;
    fetchPrevPage: boolean;
    hasNextPage: boolean;
    hasPrevPage: boolean;
  };
  open: boolean;
  query: string | string[] | any;
  selectedProviders: string[];
  selectedCollection: string[];
  selectedSort: GameSort['type'];
  expandFilter: boolean;
  isSearching: boolean;
  searchType: SearchType;
}

// Define the initial state using that type
const initialState: Search = {
  results: {
    pages: [],
    pageParams: [],
  },
  resultActions: {
    isLoading: false,
    isFetching: false,
    isFetchingNextPage: false,
    isFetchingPrevPage: false,
    fetchPrevPage: false,
    fetchNextPage: false,
    hasNextPage: false,
    hasPrevPage: false,
  },
  open: false,
  query: '',
  isSearching: false,
  expandFilter: false,
  selectedCollection: [],
  selectedProviders: [],
  selectedSort: 'novelty',
  searchType: SearchType.GAMES,
};

export const searchSlice = createSlice({
  name: 'search',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setOpenSearch: (state, action: PayloadAction<boolean>) => {
      if (action.payload === false) {
        state.isSearching = false;
      }
      state.open = action.payload;
    },
    setIsSearching: (state, action: PayloadAction<boolean>) => {
      state.isSearching = action.payload;
    },
    setResults: (
      state,
      action: PayloadAction<{
        pages: SearchResult[] | [];
        pageParams: number[];
      }>,
    ) => {
      state.results = action.payload;
      state.isSearching = false;
    },
    setResultActions: (
      state,
      action: PayloadAction<{
        isLoading: boolean;
        isFetching: boolean;
        isFetchingPrevPage: boolean;
        isFetchingNextPage: boolean;
        fetchPrevPage: boolean;
        fetchNextPage: boolean;
        hasPrevPage: boolean;
        hasNextPage: boolean;
      }>,
    ) => {
      state.resultActions = action.payload;
    },
    setQuery: (state, action: PayloadAction<string>) => {
      state.isSearching = true;
      state.query = action.payload;
    },
    setSelectedCollection: (state, action: PayloadAction<string[]>) => {
      state.selectedCollection = action.payload;
    },
    setSelectedProviders: (state, action: PayloadAction<string[]>) => {
      state.selectedProviders = action.payload;
    },
    setSelectedSort: (state, action: PayloadAction<GameSort['type']>) => {
      state.selectedSort = action.payload;
    },
    setSearchType: (state, action: PayloadAction<SearchType>) => {
      state.searchType = action.payload;
    },
    setExpandFilter: (state, action: PayloadAction<boolean>) => {
      state.expandFilter = action.payload;
    },
  },
});

export const {
  setOpenSearch,
  setIsSearching,
  setResults,
  setQuery,
  setSelectedCollection,
  setSelectedProviders,
  setSelectedSort,
  setResultActions,
  setSearchType,
  setExpandFilter,
} = searchSlice.actions;

export default searchSlice.reducer;
