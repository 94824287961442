import { useMemo } from 'react';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import client from '../utils/api';
import { useAuth } from './auth';
import { Account } from '@/types/accounts';
import { useToast } from '@purposeinplay/utils';
import { useTranslation } from '@/app/i18n/client';
import { useOtherToastMessages } from '@/hooks/use-other-toast-messages';

import { useAnalytics } from '@/hooks/use-analytics';
import { useCurrencyFormatter } from './use-currency-formatter-wrapper';
import useFiatFromCents from './fiat';
import { useAppSelector } from './redux';

const useAccounts = (showAll = false, enabled = true) => {
  const { user } = useAuth();
  const { data, isLoading, refetch, isPending } = useQuery({
    queryKey: ['accounts', showAll],
    queryFn: ({ queryKey }) => {
      const url =
        queryKey[1] === false
          ? `player/accounts`
          : `player/accounts?compatibility=false`;
      return client(url);
    },
    enabled: user?.isAuthenticated && enabled,
  });
  const currentAccount = useMemo(() => {
    if (user.isLoading) return;
    return (
      user &&
      !user.isLoading &&
      user?.currentUser &&
      data?.find((acc: Account) => acc.currency === user?.currentUser?.currency)
    );
  }, [data, user]);

  return {
    accounts: data || [],
    account: currentAccount,
    refetch,
    isLoading,
    isPending,
  };
};

const useUpdatePlayerAccount = (): UseMutationResult => {
  const { t } = useTranslation();
  const { track } = useAnalytics();
  const toastMessages = useOtherToastMessages();
  const { toast } = useToast();
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['updatePlayerAccount'],
    mutationFn: (currency: any) => {
      try {
        return client('player/accounts', { currency }, 'POST').then((res) => {
          track('account_currency_changed', {
            category: 'user',
            label: currency,
          });

          return res;
        });
      } catch (e) {
        throw e;
      }
    },
    onMutate: async (currency) => {
      await queryClient.cancelQueries({ queryKey: ['accounts'] });
      await queryClient.cancelQueries({ queryKey: ['currentUser'] });
      const prevFavoriteData = queryClient.getQueryData(['accounts']);
      queryClient.setQueryData(['accounts'], currency);
      return { prevFavoriteData };
    },
    onError: (context) => {
      //@ts-ignore
      queryClient.setQueryData(['accounts'], context.prevFavoriteData);
    },
    onSettled: () => {
      toast({
        state: 'success',
        title: toastMessages?.updated_wallet,
        autoClose: 1500,
      });
      queryClient.invalidateQueries({ queryKey: ['currentUser'] });
      queryClient.invalidateQueries({ queryKey: ['accounts'] });
    },
  });
};

const useDeletePlayerAccount = (): UseMutationResult => {
  const { t } = useTranslation();
  const toastMessages = useOtherToastMessages();
  const { toast } = useToast();
  const { mutateAsync: updateAccount } = useUpdatePlayerAccount();
  const queryClient = useQueryClient();
  const { account } = useAccounts();
  return useMutation({
    mutationKey: ['deletePlayerAccount'],
    mutationFn: (currency: any) => {
      try {
        return client(`player/accounts/${currency}`, {}, 'DELETE').then(() => ({
          currency,
        }));
      } catch (e) {
        throw e;
      }
    },
    onSuccess: (data: any) => {
      const currency = data?.currency;
      toast({
        state: 'success',
        title: toastMessages?.currency_removed,
        autoClose: 1500,
      });
      queryClient.invalidateQueries({ queryKey: ['currentUser'] });
      queryClient.invalidateQueries({ queryKey: ['accounts'] });
      if (currency && currency === account?.currency) {
        // reset to BTC to avoid having no selected account
        updateAccount('BTC');
      }
    },
  });
};

const useBalance = (
  currency: string | null = null,
  showAllAccounts = false,
) => {
  const { account = {}, accounts, refetch }: any = useAccounts(showAllAccounts);
  const { formatCurrency } = useCurrencyFormatter();
  const viewFiat = useAppSelector((state) => state.bootstrap.viewFiat);
  const foundAccount = useMemo(() => {
    if (currency) {
      return accounts.find((acc: Account) => acc.currency === currency);
    }
    return account;
  }, [currency, accounts, account]);

  const balance =
    foundAccount &&
    foundAccount?.currency &&
    (foundAccount?.amount_cents as number);

  const withdrawableBalance =
    foundAccount &&
    foundAccount?.currency &&
    (foundAccount?.available_to_cashout_cents as number);
  const fiatBalance = useFiatFromCents(foundAccount?.currency, balance);

  const fiatWithdrawableBalance = useFiatFromCents(
    foundAccount?.currency,
    withdrawableBalance,
  );
  return {
    refetch,
    balance,
    formattedBalance: !foundAccount
      ? viewFiat
        ? '$0.00'
        : '0.00000000'
      : formatCurrency(
          foundAccount?.amount_cents as number,
          foundAccount?.currency as string,
          { style: 'decimal' },
        ),
    fiatBalance,
    formattedWithdrawableBalance: !foundAccount
      ? viewFiat
        ? '$0.00'
        : '0.00000000'
      : formatCurrency(
          foundAccount?.available_to_cashout_cents as number,
          foundAccount?.currency as string,
          { style: 'decimal' },
        ),
    withdrawableBalance,
    fiatWithdrawableBalance,
  };
};

export {
  useAccounts,
  useUpdatePlayerAccount,
  useDeletePlayerAccount,
  useBalance,
};
